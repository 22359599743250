import React, { Suspense, useEffect, useState } from "react";
import {
  DashboardOutlined,
  PieChartOutlined,
  UserOutlined,
  UserSwitchOutlined,
  FileImageOutlined,
  ReadOutlined,
  BankOutlined,
  DingtalkOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Spin, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import LayoutHeader from "./Header";
import logo from "../assets/images/logo-no-background.png";
import { useQuery } from "@tanstack/react-query";
import { getActiveBillApi } from "../apis/billApi";

const { Content, Footer, Sider } = Layout;

// Pages
const DashboardPage = React.lazy(() => import("../pages/dashboard"));
const NotificationPage = React.lazy(() => import("../pages/dashboard/notification"));
const BillCreationHistoryPage = React.lazy(() => import("../pages/history/bill-creation-history"));
const LoginHistoryPage = React.lazy(() => import("../pages/history/login-history"));
const ChangePassHistoryPage = React.lazy(() => import("../pages/history/change-password-history"));
const UserPage = React.lazy(() => import("../pages/user"));
const BankListPage = React.lazy(() => import("../pages/bankManager"));

const BankName = React.lazy(() => import("../pages/listBankName"));
const ImageManagerPage = React.lazy(() => import("../pages/imageManager/bill-image-manager/bill-transfer"));
const IconManagerPage = React.lazy(() => import("../pages/iconManager"));

// Image manager Bill transfer

// Image manager Bill transfer
const ImageManagerBillTransferPage = React.lazy(() => import("../pages/imageManager/bill-image-manager/bill-transfer"));
const ImageManagerBillTransactionPage = React.lazy(() =>
  import("../pages/imageManager/bill-image-manager/bill-transaction")
);
const ImageManagerBillBalancePage = React.lazy(() => import("../pages/imageManager/bill-image-manager/bill-balance"));
const ImageManagerSmsBankingPage = React.lazy(() => import("../pages/imageManager/bill-image-manager/sms-banking"));

const BillTransferDetailPage = React.lazy(() =>
  import("../pages/imageManager/bill-image-manager/bill-transfer/detail")
);
const BillTransactionDetailPage = React.lazy(() =>
  import("../pages/imageManager/bill-image-manager/bill-transaction/detail")
);
const BillBalanceDetailPage = React.lazy(() => import("../pages/imageManager/bill-image-manager/bill-balance/detail"));
const SmsBakingDetailPage = React.lazy(() => import("../pages/imageManager/bill-image-manager/sms-banking/detail"));

// End Image manager Bill transfer
//start gameImagePagge
const WithDrawPage = React.lazy(() => import("../pages/gameManager/withDramMoney/indexv2"));
const HistoryPage = React.lazy(() => import("../pages/gameManager/history/indexv2"));
const GameScreenPage = React.lazy(() => import("../pages/gameManager/gameScreen/indexv2"));
// const HomeScreenPage = React.lazy(() => import("../pages/gameManager/homeScreen"));
const HomeScreenPage = React.lazy(() => import("../pages/gameManager/homeScreen/indexv2"));
const WithdrawalHistory = React.lazy(() => import("../pages/gameManager/withdrawalHistory/indexv2"));
const RefundHistory = React.lazy(() => import("../pages/gameManager/refunHistory/indexv2"));
const VipScreenPage = React.lazy(() => import("../pages/gameManager/vipScreen"));
//end gameImagePage
// start web app
// const ClientWebApp = React.lazy(() => import("../pages"));
const ClientWebApp = React.lazy(() => import("../pages/webapp/clientWebApp"));
const ClientInformation = React.lazy(() => import("../pages/webapp/clientWebApp/beneficiary"));
// end web app
// Setting
const Setting = React.lazy(() => import("../pages/settings"));

// End Pages
const getItem = (label, key, icon, children) => {
  return {
    label,
    key,
    icon,
    children,
  };
};

const authProtectedRoutes = [
  {
    label: "Tổng Quan",
    key: "dashboard",
    icon: <DashboardOutlined />,
    children: [
      {
        label: "Tổng Quan",
        key: "subdashboard",
        path: "/dashboard",
        component: <DashboardPage />,
        icon: <PieChartOutlined />,
      },
      {
        label: "Thông báo",
        key: "notification",
        path: "/notification",
        component: <NotificationPage />,
        icon: <FieldTimeOutlined />,
      },
    ],
  },
  {
    label: "Lịch sử",
    key: "historyIP",
    icon: <HistoryOutlined />,
    children: [
      {
        label: "Lịch sử tạo bill",
        key: "billCreationHistory",
        path: "/billCreationHistory",
        component: <BillCreationHistoryPage />,
        icon: <ReadOutlined />,
      },
      {
        label: "Lịch sử đăng nhập",
        key: "loginHistory",
        path: "/loginHistory",
        component: <LoginHistoryPage />,
        icon: <ReadOutlined />,
      },
      {
        label: "Lịch sử đổi mật khẩu",
        key: "changePassHistory",
        path: "/changePassHistory",
        component: <ChangePassHistoryPage />,
        icon: <ReadOutlined />,
      },
    ],
  },
  {
    label: "Ngân hàng",
    key: "bankListManager",
    icon: <BankOutlined />,
    children: [
      {
        label: "Danh sách bank list",
        key: "bankListManagerList",
        path: "/bankListManager",
        component: <BankListPage />,
        // icon: <BankOutlined />,
      },
    ],
  },

  {
    label: "Quản lý hình ảnh tạo bill",
    key: "imageManager",
    icon: <FileImageOutlined />,
    children: [
      {
        label: "Bill chuyển khoản",
        key: "bill-transfer",
        path: "/imageManager/bill-transfer",
        component: <ImageManagerBillTransferPage />,
      },
      {
        label: "Bill biến động",
        key: "bill-transaction",
        path: "/imageManager/bill-transaction",
        component: <ImageManagerBillTransactionPage />,
      },
      {
        label: "Bill số dư",
        key: "bill-balance",
        path: "/imageManager/bill-balance",
        component: <ImageManagerBillBalancePage />,
      },
      {
        label: "SMS Banking",
        key: "sms-banking",
        path: "/imageManager/sms-banking",
        component: <ImageManagerSmsBankingPage />,
      },
    ],
  },
  {
    label: "Hình ảnh trang game",
    key: "gameImage",
    icon: <FileImageOutlined />,
    children: [
      {
        label: "Yêu cầu rút",
        key: "withDrawMoney",
        children: [
          {
            label: "Jun88",
            key: "Jun88withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=Jun88",
          },
          {
            label: "New88",
            key: "New88withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=New88",
          },
          {
            label: "Hi88",
            key: "Hi88withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=Hi88",
          },
          {
            label: "SHBET",
            key: "SHBETwithDrawMoney",
            path: "/withDrawMoney/Image?PageGame=SHBET",
          },
          {
            label: "789BET",
            key: "789BETwithDrawMoney",
            path: "/withDrawMoney/Image?PageGame=789BET",
          },
          {
            label: "789WIN",
            key: "78WINwithDrawMoney",
            path: "/withDrawMoney/Image?PageGame=78WIN",
          },
          {
            label: "F8BET",
            key: "F8BETwithDrawMoney",
            path: "/withDrawMoney/Image?PageGame=F8BET",
          },
          {
            label: "F168",
            key: "F168withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=F168",
          },
          {
            label: "MB66",
            key: "MB66withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=MB66",
          },
          {
            label: "QQ88",
            key: "QQ88withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=QQ88",
          },
          {
            label: "OK9",
            key: "OK9withDrawMoney",
            path: "/withDrawMoney/Image?PageGame=OK9",
          },
        ],
      },
      {
        label: "Lịch sử giao dịch",
        key: "history",
        children: [
          {
            label: "Jun88",
            key: "Jun88history",
            path: "/history/Image?PageGame=Jun88",
          },
          {
            label: "New88",
            key: "New88history",
            path: "/history/Image?PageGame=New88",
          },
          {
            label: "Hi88",
            key: "Hi88history",
            path: "/history/Image?PageGame=Hi88",
          },
          {
            label: "SHBET",
            key: "SHBEThistory",
            path: "/history/Image?PageGame=SHBET",
          },
          {
            label: "789BET",
            key: "789BEThistory",
            path: "/history/Image?PageGame=789BET",
          },
          {
            label: "78WIN",
            key: "78WINhistory",
            path: "/history/Image?PageGame=78WIN",
          },
          {
            label: "F8BET",
            key: "F8BEThistory",
            path: "/history/Image?PageGame=F8BET",
          },
          {
            label: "F168",
            key: "F168history",
            path: "/history/Image?PageGame=F168",
          },
          {
            label: "MB66",
            key: "MB66history",
            path: "/history/Image?PageGame=MB66",
          },
          {
            label: "QQ88",
            key: "QQ88history",
            path: "/history/Image?PageGame=QQ88",
          },
          {
            label: "OK9",
            key: "OK9history",
            path: "/history/Image?PageGame=OK9",
          },
        ],
      },
      {
        label: "Màn hình trang chủ",
        key: "homeScreen",
        children: [
          {
            label: "Jun88",
            key: "Jun88homeScreen",
            path: "/homeScreen/Image?PageGame=Jun88",
          },
          {
            label: "New88",
            key: "New88homeScreen",
            path: "/homeScreen/Image?PageGame=New88",
          },
          {
            label: "Hi88",
            key: "Hi88homeScreen",
            path: "/homeScreen/Image?PageGame=Hi88",
          },
          {
            label: "SHBET",
            key: "SHBEThomeScreen",
            path: "/homeScreen/Image?PageGame=SHBET",
          },
          {
            label: "789BET",
            key: "789BEThomeScreen",
            path: "/homeScreen/Image?PageGame=789BET",
          },
          {
            label: "78WIN",
            key: "78WINhomeScreen",
            path: "/homeScreen/Image?PageGame=78WIN",
          },
          {
            label: "F8BET",
            key: "F8BEThomeScreen",
            path: "/homeScreen/Image?PageGame=F8BET",
          },
          {
            label: "F168",
            key: "F168homeScreen",
            path: "/homeScreen/Image?PageGame=F168",
          },
          {
            label: "MB66",
            key: "MB66homeScreen",
            path: "/homeScreen/Image?PageGame=MB66",
          },
          {
            label: "QQ88",
            key: "QQ88homeScreen",
            path: "/homeScreen/Image?PageGame=QQ88",
          },
          {
            label: "OK9",
            key: "OK9homeScreen",
            path: "/homeScreen/Image?PageGame=OK9",
          },
        ],
      },
      {
        label: "Màn hình game",
        key: "gameScreen",
        children: [
          {
            label: "Jun88",
            key: "Jun88gameScreen",
            path: "/gameScreen/Image?PageGame=Jun88",
          },
          {
            label: "New88",
            key: "New88gameScreen",
            path: "/gameScreen/Image?PageGame=New88",
          },
          {
            label: "Hi88",
            key: "Hi88gameScreen",
            path: "/gameScreen/Image?PageGame=Hi88",
          },
          {
            label: "SHBET",
            key: "SHBETgameScreen",
            path: "/gameScreen/Image?PageGame=SHBET",
          },
          {
            label: "789BET",
            key: "789BETgameScreen",
            path: "/gameScreen/Image?PageGame=789BET",
          },
          {
            label: "78WIN",
            key: "78WINgameScreen",
            path: "/gameScreen/Image?PageGame=78WIN",
          },
          {
            label: "F8BET",
            key: "F8BETgameScreen",
            path: "/gameScreen/Image?PageGame=F8BET",
          },
          {
            label: "F168",
            key: "F168gameScreen",
            path: "/gameScreen/Image?PageGame=F168",
          },
          {
            label: "MB66",
            key: "MB66gameScreen",
            path: "/gameScreen/Image?PageGame=MB66",
          },
          {
            label: "QQ88",
            key: "QQ88gameScreen",
            path: "/gameScreen/Image?PageGame=QQ88",
          },
          {
            label: "OK9",
            key: "OK9gameScreen",
            path: "/gameScreen/Image?PageGame=OK9",
          },
        ],
      },
      {
        label: "Lịch sử rút tiền",
        key: "withdrawallHistory",
        children: [
          {
            label: "Jun88",
            key: "Jun88withdrawallHistory",
            path: "/withdrawallHistory/Image?PageGame=Jun88",
          },
          {
            label: "MB66",
            key: "MB66withdrawallHistory",
            path: "/withdrawallHistory/Image?PageGame=MB66",
          },
          {
            label: "78WIN",
            key: "78WINwithdrawallHistory",
            path: "/withdrawallHistory/Image?PageGame=78WIN",
          },
        ],
      },
      {
        label: "Lịch sử hoàn tiền",
        key: "refundHistory",
        children: [
          {
            label: "Jun88",
            key: "Jun88refundHistory",
            path: "/refundHistory/Image?PageGame=Jun88",
          },
          {
            label: "MB66",
            key: "MB66refundHistory",
            path: "/refundHistory/Image?PageGame=MB66",
          },
          {
            label: "78WIN",
            key: "78WINrefundHistory",
            path: "/refundHistory/Image?PageGame=78WIN",
          },
        ],
      },
      {
        label: "Màn hình VIP",
        key: "vipScreen",
        children: [
          {
            label: "Jun88",
            key: "Jun88vipScreen",
            path: "/vipScreen/Image?PageGame=Jun88",
          },
          {
            label: "New88",
            key: "New88vipScreen",
            path: "/vipScreen/Image?PageGame=New88",
          },
          {
            label: "Hi88",
            key: "Hi88vipScreen",
            path: "/vipScreen/Image?PageGame=Hi88",
          },
          {
            label: "SHBET",
            key: "SHBETvipScreen",
            path: "/vipScreen/Image?PageGame=SHBET",
          },
          {
            label: "789BET",
            key: "789BETvipScreen",
            path: "/vipScreen/Image?PageGame=789BET",
          },
          {
            label: "78WIN",
            key: "78WINvipScreen",
            path: "/vipScreen/Image?PageGame=78WIN",
          },
          {
            label: "F8BET",
            key: "F8BETvipScreen",
            path: "/vipScreen/Image?PageGame=F8BET",
          },
          {
            label: "F168",
            key: "F168vipScreen",
            path: "/vipScreen/Image?PageGame=F168",
          },
          {
            label: "MB66",
            key: "MB66vipScreen",
            path: "/vipScreen/Image?PageGame=MB66",
          },
          {
            label: "QQ88",
            key: "QQ88vipScreen",
            path: "/vipScreen/Image?PageGame=QQ88",
          },
          {
            label: "OK9",
            key: "OK9vipScreen",
            path: "/vipScreen/Image?PageGame=OK9",
          },
        ],
      },
    ],
  },
  {
    label: "Quản lý bank thụ hưởng",
    key: "banknameManager",
    icon: <BankOutlined />,
    children: [
      {
        label: "Danh sách bank thụ hưởng",
        key: "bankname",
        path: "/BankName",
        component: <BankName />,
        // icon: <BankOutlined />,
      },
    ],
  },
  {
    label: "Quản lý icon",
    key: "iconManager",
    icon: <DingtalkOutlined />,
    children: [
      {
        label: "Danh sách icon",
        key: "icon",
        path: "/icon-manager",
        component: <IconManagerPage />,
        // icon: <BankOutlined />,
      },
    ],
  },
  {
    label: "Tài khoản",
    key: "user",
    icon: <UserOutlined />,
    children: [
      {
        label: "Danh sách tài khoản",
        key: "menuuser",
        path: "/user",
        component: <UserPage />,
        // icon: <UserSwitchOutlined />,
      },
    ],
  },
  {
    label: "Quản lý web app",
    key: "WebApp",
    icon: <AppstoreOutlined />,
    children: [
      {
        label: "Thông tin khách",
        key: "client-information",
        path: "/WebApp/client-information",
        component: <ClientWebApp />,
      },
    ],
  },
  {
    label: "Cài đặt hệ thống",
    key: "setting",
    icon: <SettingOutlined />,
    children: [
      {
        label: "Cài đặt",
        key: "base-setting",
        path: "/setting",
        component: <Setting />,
      },
    ],
  },
];
const routeAuthWithoutMenu = [
  {
    key: "bill-transfer",
    path: "/imageManager/bill-transfer/detail",
    component: <BillTransferDetailPage />,
  },
  {
    key: "bill-transaction",
    path: "/imageManager/bill-transaction/detail",
    component: <BillTransactionDetailPage />,
  },
  {
    key: "bill-balance",
    path: "/imageManager/bill-balance/detail",
    component: <BillBalanceDetailPage />,
  },
  {
    key: "sms-banking",
    path: "/imageManager/sms-banking/detail",
    component: <SmsBakingDetailPage />,
  },
  {
    key: "withDrawMoney",
    path: "/withDrawMoney/Image",
    component: <WithDrawPage />,
  },
  {
    key: "homeScreen",
    path: "/homeScreen/Image",
    component: <HomeScreenPage />,
  },
  {
    key: "gameScreen",
    path: "/gameScreen/Image",
    component: <GameScreenPage />,
  },
  {
    key: "vipScreen",
    path: "/vipScreen/Image",
    component: <VipScreenPage />,
  },
  {
    key: "history",
    path: "/history/Image",
    component: <HistoryPage />,
  },
  {
    key: "withdrawallHistory",
    path: "/withdrawallHistory/Image",
    component: <WithdrawalHistory />,
  },
  {
    key: "refundHistory",
    path: "/refundHistory/Image",
    component: <RefundHistory />,
  },
  {
    key: "client-information",
    path: "/WebApp/client-information/detail",
    component: <ClientInformation />,
  },
];
// submenu keys of first level
const rootSubmenuKeys = authProtectedRoutes.map((item, index) => {
  return item.key;
});
const itemsMenuSideBar = authProtectedRoutes.map((item, index) => {
  if (item.path === "/") return null;
  return getItem(
    item.label,
    item.key,
    item.icon,
    item.children?.map((itemChildren) => {
      if (itemChildren.children) {
        return getItem(
          itemChildren.label,
          itemChildren.key,
          itemChildren.icon,
          itemChildren.children.map((itemChildrenChildren) => {
            return getItem(itemChildrenChildren.label, itemChildrenChildren.key, itemChildrenChildren.icon);
          })
        );
      }
      return getItem(itemChildren.label, itemChildren.key, itemChildren.icon);
    })
  );
});
const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const [selectedKeys, setSelectedKeys] = useState("dashboard");
  const [components, setComponents] = useState(null);
  const keysToCheck = [
    "withDrawMoney",
    "gameScreen",
    "vipScreen",
    "homeScreen",
    "history",
    "withdrawallHistory",
    "refundHistory",
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { data: listBill, isFetching } = useQuery(
    ["listBill"],
    () => {
      return getActiveBillApi();
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    }
  );
  useEffect(() => {
    //set navigation defaults for url "/"
    if (location.pathname === "/") return navigate("/dashboard");
    // const userData = JSON.parse(localStorage.getItem("user"));
    let _item = "";
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.path === location.pathname) {
            _item = elementChildren;
          }
          if (elementChildren.children) {
            elementChildren.children.forEach((elementChildrenChildren) => {
              if (elementChildrenChildren.path === location.pathname) {
                _item = elementChildrenChildren;
              }
            });
          }
        });
    });

    routeAuthWithoutMenu.forEach((element) => {
      if (element.path === location.pathname) {
        _item = element;
        let keyMatch = null;
        keysToCheck.forEach((key) => {
          if (location.pathname.includes(key)) {
            keyMatch = key;
          }
        });
        if (keyMatch) {
          const search_key = new URLSearchParams(location.search).get("PageGame");
          if (search_key) {
            _item = {
              ..._item,
              key: `${search_key}${keyMatch}`,
            };
          }
        }
      }
    });

    if (!_item) return navigate("/404");

    setSelectedKeys(_item.key);
    handleSetOpenKeys(_item.key);
    setComponents(_item.component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handleSetOpenKeys = (expression) => {
    switch (expression) {
      case "menuuser":
        setOpenKeys(["user"]);
        break;
      case "client-information":
        setOpenKeys(["WebApp"]);
        break;
      case "bankname":
        setOpenKeys(["banknameManager"]);
        break;
      case "bankListManagerList":
        setOpenKeys(["bankListManager"]);
        break;

      case "bill-image":
        setOpenKeys(["imageManager"]);
        break;
      case "bill-transfer":
      case "bill-transaction":
      case "bill-balance":
      case "sms-banking":
        setOpenKeys(["imageManager", "bill-image"]);
        break;
      case "withDrawMoney":
      case "history":
      case "gameScreen":
      case "homeScreen":
      case "withdrawallHistory":
      case "refundHistory":
        setOpenKeys(["gameImage"]);
        break;
      case "Jun88withDrawMoney":
      case "New88withDrawMoney":
      case "Hi88withDrawMoney":
      case "SHBETwithDrawMoney":
      case "789BETwithDrawMoney":
      case "78WINwithDrawMoney":
      case "F8BETwithDrawMoney":
      case "F168withDrawMoney":
      case "MB66withDrawMoney":
      case "QQ88withDrawMoney":
      case "OK9withDrawMoney":
        setOpenKeys(["gameImage", "withDrawMoney"]);
        break;
      case "Jun88history":
      case "New88history":
      case "Hi88history":
      case "SHBEThistory":
      case "789BEThistory":
      case "78WINhistory":
      case "F8BEThistory":
      case "F168history":
      case "MB66history":
      case "QQ88history":
      case "OK9history":
        setOpenKeys(["gameImage", "history"]);
        break;
      case "Jun88gameScreen":
      case "New88gameScreen":
      case "Hi88gameScreen":
      case "SHBETgameScreen":
      case "789BETgameScreen":
      case "78WINgameScreen":
      case "F8BETgameScreen":
      case "F168gameScreen":
      case "MB66gameScreen":
      case "QQ88gameScreen":
      case "OK9gameScreen":
        setOpenKeys(["gameImage", "gameScreen"]);
        break;
      case "Jun88homeScreen":
      case "New88homeScreen":
      case "Hi88homeScreen":
      case "SHBEThomeScreen":
      case "789BEThomeScreen":
      case "78WINhomeScreen":
      case "F8BEThomeScreen":
      case "F168homeScreen":
      case "MB66homeScreen":
      case "OK9homeScreen":
      case "QQ88homeScreen":
        setOpenKeys(["gameImage", "homeScreen"]);
        break;
      case "Jun88vipScreen":
      case "New88vipScreen":
      case "Hi88vipScreen":
      case "SHBETvipScreen":
      case "789BETvipScreen":
      case "78WINvipScreen":
      case "F8BETvipScreen":
      case "F168vipScreen":
      case "MB66vipScreen":
      case "QQ88vipScreen":
      case "OK9vipScreen":
        setOpenKeys(["gameImage", "vipScreen"]);
        break;
      case "Jun88withdrawallHistory":
      case "MB66withdrawallHistory":
      case "78WINwithdrawallHistory":
        setOpenKeys(["gameImage", "withdrawallHistory"]);
        break;
      case "Jun88refundHistory":
      case "MB66refundHistory":
      case "78WINrefundHistory":
        setOpenKeys(["gameImage", "refundHistory"]);
        break;
      case "icon":
        setOpenKeys(["iconManager"]);
        break;
      case "changePassHistory":
      case "loginHistory":
      case "billCreationHistory":
        setOpenKeys(["historyIP"]);
        break;
      case "base-setting":
        setOpenKeys(["setting"]);
        break;
      default:
        setOpenKeys(["dashboard"]);
    }
  };
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(latestOpenKey ? ["gameImage", latestOpenKey] : keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onClickItemMenu = (item) => {
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.children) {
            elementChildren.children.forEach((elementChildrenChildren) => {
              if (elementChildrenChildren.key === item.key) {
                return navigate(elementChildrenChildren.path);
              }
            });
          }
          if (elementChildren.key === item.key) {
            // if (elementChildren.path.includes("imageManager")) {
            //   // input bill-balance
            //   // output BILLBALANCE
            //   // remove - syntax
            //   let CAPITALIZE = item.key.replace(/-/g, "").toUpperCase();
            //   if (CAPITALIZE === "BILLTRANSACTION")
            //     CAPITALIZE = "BILLFLUCTUATION";
            //   const bank = listBill?.data?.find(
            //     (_item) => _item.code === CAPITALIZE
            //   );
            //   return navigate({
            //     pathname: elementChildren.path,
            //     search: `?id=${bank?.id}`,
            //   });
            // }
            return navigate(elementChildren.path);
          }
        });
    });
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={280}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" className={collapsed ? styles.logoIconCosplay : styles.logoIcon} />
          {/* {!collapsed && <span> CMS</span>} */}
        </div>
        <Spin spinning={isFetching}>
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={itemsMenuSideBar}
            onClick={(item) => onClickItemMenu(item)}
          />
        </Spin>
      </Sider>

      <Layout>
        <LayoutHeader />
        <Content style={{ margin: "10px 16px 0" }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {components}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>OkVip ©2023 Created by OkVip</Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
